<template>
    <OMediaQueryProvider v-slot="{ isTouch, xs }">
        <button 
            class="btn"
            :class="isTouch ? 'btn-primary btn-touch' : 'btn-link btn-sm'"
            @click="openModal">
            {{$t('Choose Fields')}}
        </button>
        
        <ODialog v-model:show="showChooser" :title="$t('Choose Filter Fields')" size="lg" backdrop
            @shown="() => $refs.searchInput?.focus()">
            <template #headerAction>
                <input type="search" v-model="searchValue" ref="searchInput" class="form-control form-control-sm" :class="{ 'align-self-start mt-4 w-100': xs, 'ms-4 w-50': !xs }" :placeholder="$t('Search...')">
            </template>
            <div class="o365-dialog-body">
                <div class="w-100 h-100" :style="isTouch ? 'columns:1 auto;' : 'columns:4 auto;'">
                    <div v-for="col in columnList" :key="col.item.field" class=" form-check mb-2 align-items-center">
                        <input :id="'_fieldChooser'+col.item.field" class="form-check-input" type="checkbox" :disabled="col.filterItem?.applied" :checked="col.isInList" @change="handleChange($event, col)">
                        
                        <label class="form-check-label w-100" :class="{ 'text-muted': col.filterItem?.applied, 'fs-5': isTouch }" :for="'_fieldChooser'+col.item.field">
                            {{col.item.caption??col.item.name}}
                        </label>
                    </div>
                </div>
            </div>
        </ODialog>
    </OMediaQueryProvider>
</template> 

<script setup>
import { ref, computed, onUnmounted } from 'vue';
import { ODialog, OMediaQueryProvider } from 'o365-ui-components';

const props = defineProps({
    filterObject: Object
});
const showChooser = ref(false);
const searchValue = ref(null);

const columnList = computed(() => {
    return props.filterObject.columns.reduce((arr, col) => {
            if (col.hideFromList) { return arr; } 
            if (!col.unbound || col.filter) {
                if (searchValue.value) {
                    const searchString = searchValue.value.toLowerCase();
                    const caption = (col.caption?? col.name??col.field).toLowerCase();
                    if (!caption.includes(searchString)) {
                        return arr;
                    }
                }

                arr.push({
                    item: col,
                    filterItem: props.filterObject.filterItems[col.name],
                    isInList: props.filterObject.fieldFilters.includes(col.name),
                    //disabled: !!props.filterObject._fieldFilters.find(x => x.name===col.field)
                });
            }
        return arr;
    }, []);
}); 

/*
const columnList = computed(() => {
    return props.filterObject.columns.reduce((arr, col) => {
        if (col.filter) {
            arr.push({
                item: col,
                filterItem: props.filterObject.filterItems[col.field],
                isInList: props.filterObject.fieldFilters.includes(col.field),
                //disabled: !!props.filterObject._fieldFilters.find(x => x.name===col.field)
            });
        }
        return arr;
    }, []);
}); 
*/

function handleChange(e, col) {
    if (e.target.checked) {
        props.filterObject.setFieldFilters([col.item.name]);
    } else {
        props.filterObject.removeFieldFilter(col.item.field);
    }
    e.target.blur();
}

async function openModal() {
    // searchValue.value = null;
    showChooser.value = true;
}

</script>

<style scoped>
    .btn-touch {
        position: absolute;
        right: 10px;
        bottom: 10px;
    }
</style>